body {
  /* background: #fbfdfc; */
  font-family: "Roboto", sans-serif;
  color: #444;
  word-wrap: break-word;
}

h1 {
  font-family: 'Prompt', sans-serif;
  font-size: 36px;
  font-weight: 400;
  color: #425551;
  margin: 0;
}

h2 {
  font-family: 'Prompt', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #444;
  margin: 0;
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #444;
  margin-bottom: 0
}

.container {
  max-width: 1000px;
  width: 80%;
  margin-left: 60px;
  margin-right: 60px;
}


.title {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.logo {
  height: 24px;
  /* margin-left: 32px; */
  margin: 10px 0 4rem 0;
  fill: #fff;
}

.custom-button {
  display: flex;
  align-items: center;
  background-color: #5FA598;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px 16px 10px 16px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #5FA598;
  cursor: pointer;
}

.custom-button:disabled {
  background-color: #aaa;
  cursor: auto;
}

.custom-button:disabled::before {
  transform: none;
}

.custom-button:disabled:active {
  transform: none;
}

.custom-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

.custom-button:hover:before {
  transform: scaleX(1);
}

.custom-button:active {
  transform: scale(0.95);
  transition: transform 0.1s;
}

.outline-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #D15252;
  border-radius: 10px;
  border-color: #D15252;
  border-style: solid;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s;
}

.outline-button:hover {
  /* background-color: #D15252; */
  cursor: pointer;
}

.outline-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

.outline-button:hover:before {
  transform: scaleX(1);
}

.outline-button:active {
  transform: scale(0.95);
  transition: transform 0.1s;
}

.outline-button:disabled {
  pointer-events: none;
}

.home-empty-state {
  background-color: #5FA598;
  height: 400px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.home-empty-state-left {
  display: flex;
  flex-direction: column;
  margin: 6rem 2rem;
  min-width: 300px;
  width: 50%;
  color: white;
}

.home-empty-state-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2rem;
  margin-left: 2rem;
  opacity: 0.3;
}

@media (max-width: 1200px) {
  .container {
    margin: 0 40px 0 40px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: auto;
    width: 90%;
  }

  .title {
    margin-top: 1rem;
    flex-direction: column;
  }

  .title .custom-button {
    margin-top: 1rem;
  }

  h1 {
    font-size: 30px;
  }

  .home-empty-state {
    height: 60vh;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .home-empty-state-left {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
    align-items: center;
  }

  .home-empty-state-right {
    margin: 3rem 0 0 0;
  }

}