.links {
  display: flex;
  margin-top: 3rem;
}

.link {
  cursor: pointer;
  margin-right: 40px;
  color: #888;
  text-decoration: none;
}

.active {
  position: relative;
  font-weight: 500;
  color: #444;
}

.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #2EC790;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 2rem;
}

.visible {
  display: block;
}

.setting-fields {
  display: flex;
  flex-direction: row;
}

.setting-fields-columns {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.text-input {
  padding-top: 12px;
  padding-bottom: 12px;
}

.account-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.account-text-buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.input-title2 {
  font-family: "Roboto", sans-serif;
  color: #888;
}

.description-text {
  line-height: 150%;
  margin: 10px 0 10px 0;
}

.textbox {
  width: 100%;
  margin: 10px 0 10px 0;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  line-height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
  resize: none;
  overflow: hidden;
}

.textbox:focus {
  border-color: #5FA598;
}

.billing-pricing-section {
  display: flex;
  flex-direction: column;
}


.billing-pricing-section p {
  color: #444;
  opacity: 80%;
  /* text-align: center; */
}

.billing-pricing {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.billing-pricing-boxes {
  width: 90%;
  max-width: 400px;
  min-width: 200px;
  margin: 1rem;
  border: rgba(0, 0, 0, 0.2) 2px solid;
  /* background-color: rgba(0, 0, 0, 0.05); */

  border-radius: 30px;
  /* margin: 4rem auto 6rem auto; */
  box-sizing: border-box;
  padding: 40px;
}

.billing-pricing-boxes h1{
  opacity: 90%;
  color: #444;
  margin: 0 0 1rem 0;
}

.billing-pricing-boxes h2{
  opacity: 90%;
  /* margin: 0 0 2rem 0; */
  color: #444;
}

.billing-pricing-boxes h3{
  opacity: 90%;
  margin: 0 0 1rem 0;
  color: #444;
}


@media screen and (max-width: 1000px) {
  .billing-pricing {
    flex-direction: column;
  }
}