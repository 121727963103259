
.landing-body {
    /* height: 200vh; */
    background: linear-gradient(164.14deg, #1A1E1D 0%, #245149 23.86%, #4B8E82 51.87%, #245149 77.81%, #2F3736 99.59%);
    background-image:
    radial-gradient(circle at 50% 100%, #4B8E82 0px, transparent 24%),
    linear-gradient(164.14deg, #1A1E1D 0%, #245149 23.86%, #1A1E1D 100%);
    font-family: "Roboto", sans-serif;
}

.landing-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 32px;
    margin: auto;
    max-width: 1200px;
}
  
.landing-logo {
    width: 150px;
  }
  
  .landing-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .landing-links a {
    transition: color 0.3s;
    text-decoration: none;
    font-family: 'Prompt', sans-serif;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }

  .landing-links a:hover {
    color: #00B897;
    transition: color 0.3s;
  }
  
  .header-button {
    padding: 0;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Prompt', sans-serif;
    cursor: pointer;
    transition: color .3s ease-in-out;
    background: none;
    border: none;
  }

  .header-button:hover {
    color: #4FDBA9;
  }

  .signin-button {
    background: none;
    border: 1px solid #fff;
    border-radius: 20px;
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    color: white;
    padding: 6px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .signin-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s;
  }

  .landing-container {
    max-width: 1400px;
    width: 90%;
    margin: auto;

  }

  .landing-hero {
    max-width: 1000px;
    display: flex;
    margin:auto;
    padding: 2rem 0;
  }

  .landing-hero h1 {
    color: #fff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 52px;
  }

  .landing-hero p {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    margin: 1rem 0 2rem 0;
    line-height: 180%;
    opacity: 90%;

  }
  
  .landing-left-column,
  .landing-right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    text-align: left;
  }
  
  .landing-left-column {
    /* background-color: #f1f1f1; */
    margin-right: 10%;
  }
  
  .landing-right-column {
    /* background-color: #f1f1f1; */
    align-items: flex-end;
    gap: 10px;
  }


@keyframes fadeIn{
  to {
    opacity: 1;
  }
}

.landing-interview-images {
  width: 100%;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;

}

.landing-interview-images:nth-of-type(odd) {
  margin-right: 10%;
}

.landing-interview-images:nth-child(1) {
  animation-delay: 1s;
}

.landing-interview-images:nth-child(2) {
  animation-delay: 4s;
}

.landing-interview-images:nth-child(3) {
  animation-delay: 9s;
}

.landing-interview-images:nth-child(4) {
  animation-delay: 14s;
}

  .landing-demo-section {
    border-radius: 16px;
    max-width: 800px;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 6rem;
    box-shadow: 0px 4px 30px rgba(0, 70, 60, 0.6);
  }  

  .landing-demo-section-top {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 62px 0 20px;
    background-color: #353535;
    color: #DDD;
    height: 48px;
    max-width: 800px;
    width: 100%;
    border-radius: 16px 16px 0 0;
  }

  .landing-demo-section-main {
    display: flex;
    justify-content: flex-end;
    background-color: #E7F8F2;
    max-width: 800px;

    height: 600px;
    width: 100%;
    border-radius: 0 0 16px 16px;
  }

  
  .landing-demo-container {
    max-width: 800px;
    width: 90%;
    margin: 0 auto;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 580px;
    /* overflow-y: scroll; */
    position: relative;
    /* bottom: 2rem; */
    /* z-index: 0; */
  }


  .landing-demo-chat-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 480px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .landing-demo-chat-container::-webkit-scrollbar {
    display: none;
  }

  .landing-demo-section h1 {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 42px;
    line-height: 69%;
  }

  .landing-demo {
    box-sizing: border-box;
    /* max-width: 1000px;
    width: 90%; */
    height: 800px;
    text-align: center;
    margin: auto;
    padding: 40px;
    margin-top: 0 ;
    background: linear-gradient(180deg, #255048 0%, #488076 52.08%, #1D4D44 100%);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.36);
    border-radius: 20px;
  }

  .landing-demo p {
    line-height: 150%;
    width: 80%;
    max-width: 600px;
    margin: auto;
    color: #D4DDDC;
    font-size: 20px;
  }

  .landing-demo-box {
    box-sizing: border-box;
    width: 90%;
    height: 600px;
    margin: auto;    
    margin-top: 2rem;
    background: #E7F8F2;
    border: 5px solid #CFE8DF;
    border-radius: 30px;
  }

  .landing-benefits-section {
    width: 90%;
    /* max-width: 1200px; */
    margin: 6rem auto 6rem auto;
    display: flex;
    flex-direction: column;
  }

  .landing-benefits-section h1 {
    color: #fff;
    text-align: center;
  }

  .landing-benefits-section p {
    color: #fff;
    opacity: 80%;
    text-align: center;
  }

  .landing-benefits {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }


  .landing-benefits-boxes {
    width: 90%;
    max-width: 400px;
    min-width: 200px;
    margin: 1rem;
    border: rgba(255, 255, 255, 0.2) 2px solid;
    background-color: rgba(255, 255, 255, 0.05);
    flex: 0 0 300px;
    border-radius: 30px;
    box-sizing: border-box;
    padding: 40px;
  }

  .landing-benefits-boxes h1{
    opacity: 90%;
    color: #fff;
    margin: 0 0 1rem 0;
  }

  .landing-benefits-boxes h2{
    opacity: 90%;
    /* margin: 0 0 2rem 0; */
    color: #fff;
  }

  .landing-benefits-boxes h3{
    opacity: 90%;
    margin: 0 0 1rem 0;
    color: #fff;
  }

  .landing-benefits-boxes p{
    opacity: 80%;
    color: #fff;
    line-height: 150%;
    text-align: left;
  }


  .landing-how-section {
    color: #fff;
    text-align: center;
    margin-top: 8rem;
  }

  .landing-how-section h1 {
    color: #fff;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 120%;
  }

  .landing-how-section p {
    max-width: 600px;
    color: #fff;
    margin: auto;
    font-size: 20px;
    line-height: 150%;
    opacity: 90%;
  }

  .landing-how-columns {
    max-width: 1000px;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    text-align: left;
    
  }

  .landing-how-left-column {
    width: 60%;
  }

  .landing-how-right-column {
    margin: 2% 0 0 4%;
    width: 40%;
  }
  
  .landing-how-right-column h2{
    color: #fff;
    margin-bottom: 0.5rem;
  }
  
  .landing-how-right-column-text{
    font-size: 15px;
    line-height: 180%;
    opacity: 90%;
  }

  .landing-how-right-column-reverse {
    margin: 2% 4% 0 4%;
    width: 40%;
  }
    
  .landing-how-right-column-reverse h2{
    color: #fff;
    margin-bottom: 0.5rem;
  }

  .landing-how-right-column-reverse-narrow {
    display: none;
    margin: 2% 4% 0 4%;
    width: 40%;
  }

  .landing-how-right-column-reverse-narrow h2{
    color: #fff;
    margin-bottom: 0.5rem;
  }


  .landing-how-image {
    width: 100%;
  }

  .bottom-decoration {
    width: 100%;
    margin-bottom: -6px;
  }

  .landing-pricing-section {
    width: 90%;
    max-width: 1200px;
    /* text-align: center; */
    margin: 6rem auto 6rem auto;
    display: flex;
    flex-direction: column;
  }

  .landing-pricing-section h1 {
    color: #fff;
    text-align: center;
  }

  .landing-pricing-section p {
    color: #fff;
    opacity: 80%;
    text-align: center;
  }

  .landing-pricing {
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
  }

  .landing-pricing-boxes {
    width: 90%;
    max-width: 400px;
    min-width: 200px;
    margin: 1rem;
    border: rgba(255, 255, 255, 0.2) 2px solid;
    background-color: rgba(255, 255, 255, 0.05);

    border-radius: 30px;
    /* margin: 4rem auto 6rem auto; */
    box-sizing: border-box;
    padding: 40px;
  }

  .landing-pricing-boxes h1{
    opacity: 90%;
    color: #fff;
    margin: 0 0 1rem 0;
  }

  .landing-pricing-boxes h2{
    opacity: 90%;
    /* margin: 0 0 2rem 0; */
    color: #fff;
  }

  .landing-pricing-boxes h3{
    opacity: 90%;
    margin: 0 0 1rem 0;
    color: #fff;
  }

  .landing-pricing-boxes p{
    opacity: 80%;
    color: #fff;
    line-height: 150%;
    text-align: left;
  }

  .landing-footer {
    background-color: #00B897;
    display: flex;
    align-content: center;
  }

  .landing-footer-container {
    max-width: 1000px;
    width: 90%;
    margin: 0.5rem auto 1rem auto;
    display: flex;
    align-content: center;
    justify-content: space-between;
    color: #fff;
    font-size: 15px;
  }

  .landing-footer-cc {
    align-items: center;
    opacity: 60%;
  }

  .landing-footer-links {
    opacity: 90%;
    display: flex;
    gap: 20px;
  }

  .landing-footer-links button {
    cursor: pointer;
    padding: 0;
    color: #ffffff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    transition: color .3s ease-in-out;
    background: none;
    border: none;
  }

  .landing-footer-links button:hover {
    color: #bdffe8;
  }

  @media screen and (max-width: 768px) {
    .landing-hero {
      flex-direction: column;
    }

    .landing-left-column {
      margin: 0 auto;
      text-align: center;
    }

    .landing-left-column button {
      margin:auto;
    }


    .landing-right-column {
      justify-content: center;
      margin: auto;
      margin-top: 2rem;
    }

    .landing-links {
      display: none;
    }

    .landing-how-columns {
      flex-direction: column;
      text-align: center;
      margin-top: 4rem;
    }
    
    .landing-how-left-column, .landing-how-right-column {
      width: 100%;
      margin: auto;
    }
  
    .landing-how-right-column {
      margin-top: 1rem;
    }

    .landing-how-right-column-reverse {
      display: none;
    }

    .landing-how-right-column-reverse-narrow {
      display: block;
      width: 100%;
      margin: auto;
      margin-top: 1rem;
    }
  

    .landing-interview-images {
      width: 90%;
    }

    .landing-demo-section {
      margin-top: 3rem;
    }

    .landing-how-section {
      margin-top: 6rem;
    }


    .landing-pricing {
      flex-direction: column;
      margin: auto;
    }

    .landing-footer-container {
      flex-direction: column;
      margin-top: 1rem;
      width: 90%;
      text-align: center;
    }
    .landing-footer-links {
      /* width: 100%; */
      margin: 0.5rem auto 0 auto;
    }
  }

  @media screen and (max-width: 400px) {
    .landing-how-columns {
      flex-direction: column;
    }
    
    .landing-how-left-column, .landing-how-right-column {
      width: 100%;
      margin: auto;
    }

    .landing-how-right-column {
      margin-top: 1rem;
    }

    .landing-demo-section {
      margin: auto;
    }


  }