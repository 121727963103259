.sidebar {
    position: fixed;
    height: 100%;
    width: 220px;
    background-color: #2F5952;
    /* border-right: 2px solid #CBDDD7; */
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    line-height: 280%;
    transition: transform 0.3s ease-in-out;
}

.sidebar a {
    color: #fff;
    font-family: 'Prompt', sans-serif;
    padding: 0 10px 0 20px;
    font-weight: 400;
    text-decoration: none;
    transition: background 0.4s;
}

.sidebar a:hover {
    background-color: #5FA598;
    font-weight: 500;
}

.sidebar-icon {
    margin-bottom: -6px;
    margin-right: 10px;
    color: #D5DEDC;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.header-bar {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding-left: 60px;
    align-items: center;
    height: 66px;
    /* border-bottom: 2px solid #CBDDD7; */
    z-index: 20;
}

.page-name {
    color: #5FA598;
    font-family: 'Prompt', sans-serif;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
  }

  .sub-header-bg {
    height: 12px;
    width: 100%;
    background: #EAF4F1;
    box-shadow: inset 0 4px 9px -6px rgba(0, 0, 0, 0.4);

  }

  .main-content {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    margin-left: 220px;
    /* width: 100%; */
  }

  .user-icon {
    cursor: pointer;
    height: 36px;
    margin-right: 32px;
  }

  @media (max-width: 1200px) {
    
    .header-bar {
      padding-left: 40px;
    }

    /* .container {
      margin: 0 40px 0 40px;
    } */

  }

  @media (max-width: 768px) {
    .sidebar {
      width: 60%;
      /* height: 100%; */
      /* flex-direction: column; */
      /* border-right: none;
      border-bottom: none; */
      /* border-right: 2px solid #CBDDD7; */
      z-index: 10;
      transform: translateX(-100%);
    }

    .main-content {
      /* height: 100vh; */
      display: flex;
      flex-direction: column;
      margin: auto;
      /* width: 100%; */
    }

    .header-bar {
      padding: 0;
    }

    .hamburger {
      display: block;
      margin-left: 32px;

    }
  
    .sidebar.open {
      transform: translateX(0);
      box-shadow: 10px 10px 50px rgb(0, 0, 0, 0.2);
    }
  }
  