
.interview-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e7f8f2;
  height: 100vh;
  justify-content: space-between;
  position: relative;
}

.interview-logo {
  /* margin: auto; */
  height: 40px;
  margin-top: 2rem;
}

.interview-header {
  display: flex;
  justify-content: center;
  width: 100%;
  /* background-color: #e7f8f2; */
  height: 300px;
  z-index: 1;
}

.interview-container {
  color: #444;
  max-width: 800px;
  width: 80%;
  margin: auto;
  /* margin-top: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* height: 80vh; */
  /* overflow-y: scroll; */
  position: relative;
  bottom: 2rem;
  z-index: 0;
}

.interview-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(100vh - 320px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.interview-chat-container::-webkit-scrollbar {
  display: none;
}


.interview-chat-top-overlay {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 10%;
  background: linear-gradient(to bottom, rgba(231, 248, 242, 1), rgba(231, 248, 242, 0));
}

.interview-chat-bottom-overlay {
  position: fixed;
  bottom: 112px;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(to top, rgba(231, 248, 242, 1), rgba(231, 248, 242, 0));
}

.right-align {
  text-align: right;
}

.sender-title {
  margin-bottom: 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.user-bubble {
  background-color: #fff;
  text-align: left;
  display: inline-block;
  line-height: 150%;
  border-radius: 50px;
  border-radius: 20px 0 20px 20px;
  padding: 12px 20px 12px 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  margin-left: 10%;
  /* margin-right: 4%; */
}

.chatbot-bubble {
  background-color: #F9FFFD;
  border-radius: 0 20px 20px 20px;
  padding: 12px 20px 12px 20px;
  line-height: 150%;
  display: inline-block;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  margin-right: 10%;
  /* margin-left: 4%; */
}

.interview-input-box {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  flex-grow: 1;
  resize: none;
  padding: 16px 60px 16px 20px;
  padding-right: 60px;
  line-height: 150%;
  background: #FFFFFF;
  border: 2px solid #CFE8DF;
  border-radius: 30px;
  z-index: 2;
}

.interview-input-box::-webkit-scrollbar {
  display: none;
}


.interview-input-box:focus {
  outline: none;
  box-shadow: 0 0 20px #E3FFF5;
}

.send-button {
  padding: 10px;
  fill: #4FDBA9;
  position: absolute;
  right: 20px;
  width: 30px;
  transition: transform 0.2s;
  z-index: 2;
}

.send-button:before {
  transform: scale(0);
  transition: transform 0.5s;
}

.send-button:hover {
  transform: scale(1.05);
  transition: transform 0.1s;
}

.send-button:active {
  transform: scale(0.95);
  transition: transform 0.1s;
}

.send-button:disabled {
  filter: invert(19%) sepia(98%) saturate(0%) hue-rotate(3deg) brightness(96%) contrast(118%);
  transform: none;
}

 .typing-indicator span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1.5px;
  background-color: #9E9EA1;
  display: block;
  border-radius: 50%;
  opacity: 0.3;
}

.typing-indicator span:nth-child(1){
    animation: 1.2s blink infinite .3333s;
}

.typing-indicator span:nth-child(2){
    animation: 1.2s blink infinite .6666s;
}

.typing-indicator span:nth-child(3){
    animation: 1.2s blink infinite .9999s;
}

@keyframes blink
{
    50%
    {
        opacity: 1;
    }
}


@keyframes pulse
{
    50% {
        transform: scale(1.05);
    }
}