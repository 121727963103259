
.firebaseui-button.mdl-button--raised.mdl-button--colored {
    background-color: rgb(0, 184, 151) !important;
    color: white !important;
    border-radius: 10px !important;
}

.firebaseui-button.mdl-button--raised.mdl-button--colored:hover {
    background: rgb(34, 200, 170) !important;
}


.firebaseui-button.mdl-button--primary.mdl-button--primary {
    color: rgb(0, 184, 151) !important;
    border-radius: 10px !important;
}

.firebaseui-idp-button, .firebaseui-tenant-button {
    border-radius: 10px !important;
    box-shadow: none !important;
    border: 1.5px solid #aaa !important;
}


.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
    background-color: rgb(0, 184, 151)  !important;
    
}

.mdl-progress > .progressbar {
    background-color: rgb(0, 184, 151)  !important;
}

.mdl-progress > .bufferbar {
    background-image: linear-gradient(
            90deg,
            hsla(0, 0%, 100%, 0.7),
            hsla(0, 0%, 100%, 0.7)
        ),
        linear-gradient(90deg, rgb(0, 184, 151) , rgb(0, 184, 151) ) !important;
    z-index: 0;
    left: 0;
}

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(
            90deg,
            hsla(0, 0%, 100%, 0.9),
            hsla(0, 0%, 100%, 0.9)
        ),
        linear-gradient(90deg, #ff626e, #ff626e) !important;
        
}