.sign-in-box {
    text-align: center;
    height: 400px;
    width: 300px;
}

.sign-in-box h1 {
    margin: 1rem 0 0.5rem 0;
    font-size: 24px;
}

.sign-in-box-line {
    border: 1px solid #aaa;
    margin: auto;
    width: 84%;
}
