input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.project-container {
  padding: 20px;
  font-family: 'Roboto';
  color: #444;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 2rem 0;
}

.left-button {
  margin-right: auto;
}

.right-button {
  margin-left: auto;
}

.project-title {
  padding: 0;
  font-family: 'Prompt', sans-serif;
  font-size: 36px;
  border: none;
  background: none;
  outline: none;
  width: 100%;
}

.project-tab-links {
  display: flex;
}

.link {
  cursor: pointer;
  margin-right: 40px;
  color: #888;
}

.active {
  position: relative;
  font-weight: 500;
  color: #444
}

.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #2EC790;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 2rem;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.text-button {
  padding: 0;
  color: #2EC790;
  font-size: 16px;
  cursor: pointer;
  transition: color .3s ease-in-out;
  background: none;
  border: none;
}

.text-button:hover {
  color: #4FDBA9;
}

.text-button:active {
  transform: scale(0.95);
  transition: transform 0.1s;
}

.text-button:disabled {
  color: #797c7b;
}

.configuration-inputs {
  display: flex;
  gap: 6%;
}

.configuration-inputs h5 {
  margin-top: 1rem;
}

.configuration-title {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.project-status {
  color: #27A376;
  font-family: 'Prompt', sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}

.interview-link {
  margin-top: 1rem;
  color: #5FA598;
  font-size: 16px;
}

@media (max-width: 1100px) {
  .configuration-inputs {
    flex-direction: column;
  }
}


@media (max-width: 768px) {
  .project-title {
    font-family: 'Prompt', sans-serif;
    font-size: 36px;
    padding-top: 1rem;
    text-align: center;
  }

  .configuration-inputs {
    flex-direction: column;
  }

  .project-status {
    text-align: center;
    margin-top: 0.5rem;
  }
  .configuration-title {
    display: block;
  }
}
