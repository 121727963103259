.projects-table {
    border-collapse: collapse;
    width: 100%;
  }

  .table-header {
    border-bottom: 1px solid #CCDDD7;
    text-align: left;
    padding: 0.5rem;
    padding-bottom: 1rem;
    color: #8CA79E;
    font-size: 16px;
    font-weight: 500;
  }

  .table-row:hover {
    background-color: #F1FBF8;
    cursor: pointer;
  }
  
  .table-cell {
    font-size: 16px;
    padding: 0.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #333;
  }


.interviews-projects-table {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    max-width: 800px;
    width: 100%;
  }

  .interviews-table-header {
    border-bottom: 1px solid #CCDDD7;
    text-align: left;
    padding: 0.5rem;
    /* padding-bottom: 1rem; */
    color: #444;
    font-size: 15px;
    font-weight: 600;
  }

  .interviews-table-row:hover {
    background-color: #F1FBF8;
    cursor: pointer;
  }
  
  .interviews-table-cell {
    font-size: 14px;
    padding: 0.6rem 0.5rem 0.6rem 0.5rem;
    color: #333;
  }