.text-input {
    padding-top: 12px;
    padding-bottom: 12px;
}

.input-title {
    font-family: 'Prompt', sans-serif;
    font-size: 20px;
    margin-top: 1rem;
}

.description-text {
    line-height: 150%;
    margin: 10px 0 10px 0;
}

.textbox {
    width: 100%;
    /* height: 8rem; */
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    line-height: 150%;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    border-radius: 5px;
    outline: none;
    transition: all 0.3s ease-in-out;
    resize: none;
    overflow: hidden;
}

.textbox:focus {
    border-color: #5FA598;

    /* transform: translateY(-2px); */
  }