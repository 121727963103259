.ir-chat-container {
    max-width: 800px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 4rem;
  }
  
  .message {
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .message.user {
    /* background-color: #e6f7ff; */
    color: #333;
  }
  
  .message.assistant {
    /* background-color: #f7fbff; */
    color: #888;
  }
  