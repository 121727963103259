/* Table styles */
.account-table {
    width: 100%;
    margin-bottom: 1rem;
    border-collapse: collapse;
  }
  
  .account-table th,
  .account-table td {
    text-align: left;
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .account-table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .account-table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  /* Pagination styles */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }
  
  .pagination button {
    background-color: transparent;
    border: 1px solid #dee2e6;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #f8f9fa;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  /* Filter input styles */
  .filter-input {
    display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 1rem;
  }
  
  .filter-input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  